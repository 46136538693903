//
// @file
// Sitewide default Styles.
// This defines all the base unclassed HTML tag styles for your site.
//
// If a WYSIWYG is available, it should reference this file for
// for styling, so it matches the user facing page as close as possible.
// The only class selectors used here, are those made available to the
// WYSIWYG editor.
//

@import "variables";

/* apply a natural box layout model to ALL elements */
* { @include box-sizing(border-box); }

// Import Font Face fonts
@font-face {
    font-family: 'BebasNeueRegular';
    src: url('../../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.eot');
    src: url('../../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.woff') format('woff'),
         url('../../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.ttf') format('truetype'),
         url('../../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fontello';
    src: url('../../fonts/fontello-de389d13/font/fontello.eot');
    src: url('../../fonts/fontello-de389d13/font/fontello.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/fontello-de389d13/font/fontello.woff') format('woff'),
         url('../../fonts/fontello-de389d13/font/fontello.ttf') format('truetype'),
         url('../../fonts/fontello-de389d13/font/fontello.svg#Fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

@include establish-baseline();

html > body {
  //@include debug-vertical-alignment();
  @include adjust-leading-to(2);
  color: $primary-color;
  font-family: $primary-typeface;
  width: 100%;
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
}

h1 {
  $font-size: $fs-xxxxxl;
  @include adjust-font-size-to($font-size);
  @include rhythm(1, 0, 0, 1, $font-size);
  font-family: $secondary-typeface;
  font-weight: normal;
  opacity: .999;
  //@include breakpoint(max-width 667px) {
  @media only screen and (max-width: 667px) {
    font-size: $fs-xxxl;
  }
}

h2 {
  $font-size: $fs-xxxl;
  @include adjust-font-size-to($font-size);
  @include rhythm(1.5, 0, 0, 0, $font-size);
  font-family: $secondary-typeface;
  font-weight: normal;
  opacity: .999;
  //@include breakpoint(max-width 667px) {
}

h3 {
  $font-size: $fs-l;
  @include adjust-font-size-to($font-size);
  @include rhythm(1, 0, 0, 0, $font-size);
  font-family: $secondary-typeface;
  font-weight: normal;
  opacity: .999;
}

h4 {
  $font-size: $fs-n;
  @include adjust-font-size-to($font-size);
  @include rhythm(1, 0, 0, 0, $font-size);
  font-family: $primary-typeface;
  font-weight: bold;
  text-transform: uppercase;
}

h5 {
  $font-size: $fs-n;
  @include adjust-font-size-to($font-size);
  @include rhythm(1, 0, 0, 0, $font-size);
  font-family: $primary-typeface;
  font-weight: normal;
  text-transform: uppercase;
}

h6 {
  $font-size: $fs-s;
  @include adjust-font-size-to($font-size);
  @include rhythm(1, 0, 0, 0, $font-size);
  font-family: $primary-typeface;
}

p {
  @include rhythm(0, 0, 0, 1, $fs-n);
}

img {
  @include margin-trailer(1);
  height: auto;
  max-width: 100%;
}

blockquote {
  @include rhythm(1, 0, 0, 1, $fs-n);
}

em {
  color: darken($primary-color, 10%);
  //font-family: monospace;
}

strong {
  font-weight: bold;
}

small {
  font-size: $fs-s;
}

//
// Links
//

a {
  color: $red;
  text-decoration: none;
  @include transition(
    color 0.1s linear,
    background 0.1s linear,
    padding 0.1s ease-in-out
  );
  &:visited {
  }

  &:hover,
  &:active {
    color: darken($red, 10%);
    text-decoration: underline;
  }
}

//
// Lists
//

ul,
ol {
  @include rhythm(1, 0, 0, 1, $fs-n);
}

dt {
  $font-size: $fs-m;
  font-family: $secondary-typeface;
  @include adjust-font-size-to($font-size);
  @include adjust-leading-to(1.5, $font-size);
  font-weight: normal;
}

li {
  a:link, a.active {
    color: $red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

//
// WYSIWYG available Styles.
//

.intro {
  $font-size: $fs-xl;
  @include adjust-font-size-to($font-size);
  @include padding-leader(.25, $font-size);
  @include margin-trailer(.75, $font-size);
}

.fine-print {
  $font-size: $fs-s;
  @include adjust-font-size-to($font-size);
  // Align every third line to every second baseline.
  @include adjust-leading-to(1.5, $font-size);
  // Add margin based on the local leading.
  @include margin-trailer(4/3);
  display: block;
}

.drop-cap {
  &:first-letter {
    font-size: 300%;
    float: left;
    margin: .133em .125em 0 0;
    text-transform: uppercase;
  }
}

.image-left {
  float: left;
  margin-right: 1em;
}

.image-right, .float-right {
  clear: left;
  float: right;
  margin-left: 1em;
}

.no-margin {
  margin: 0;
}

.hidden{
  display: none;
}

//
// Fix Admin Menu Width
//
#admin-menu{
  margin: 0;
  padding: 0;
}
